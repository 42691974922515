<template>
  <div class="edit-div">
    <div v-if="!successSave">
      <!-- Administrator Account Information -->
      <div class="form-container" v-if="!$route.query.agency">
        <p class="form-title">{{ $t("label.administratorAccount") }}</p>
        <div class="form-element">
          <label for="name"
            >{{ $t("label.name") }} <span class="required">*</span></label
          >
          <input
            type="text"
            id="name"
            v-model="name"
            :required="type === 'preagency'"
          />
        </div>
        <div class="form-element">
          <label for="surname"
            >{{ $t("label.surname") }} <span class="required">*</span></label
          >
          <input
            type="text"
            id="surname"
            v-model="surname"
            :required="type === 'preagency'"
          />
        </div>
        <div class="form-element">
          <label for="email"
            >{{ $t("label.email") }} <span class="required">*</span></label
          >
          <input
            type="email"
            id="email"
            v-model="email"
            :required="type === 'preagency'"
          />
        </div>
        <div class="form-element">
          <label for="emailConfirmation"
            >{{ $t("label.emailConfirmation") }}
            <span class="required">*</span></label
          >
          <input
            type="email"
            id="emailConfirmation"
            v-model="emailConfirmation"
            :required="type === 'preagency'"
          />
        </div>
        <div class="error-message" v-if="emailCheck != ''">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
            />
          </svg>
          <span>{{ emailCheck }}</span>
        </div>
        <div class="form-element">
          <span class="label"
            >{{ $t("label.gender") }} <span class="required">*</span></span
          >
          <ul class="form-list">
            <li>
              <input
                type="radio"
                id="male"
                v-model="gender"
                value="male"
                checked="checked"
              />
              <label for="male">{{ $t("label.male") }}</label>
            </li>
            <li>
              <input type="radio" id="female" v-model="gender" value="female" />
              <label for="female">{{ $t("label.female") }}</label>
            </li>
          </ul>
        </div>
      </div>

      <!-- Agency Information -->
      <div class="form-container" v-if="!$route.query.agency">
        <p class="form-title">{{ $t("label.agencyInformation") }}</p>
        <div class="form-element">
          <label for="agencyName"
            >{{ $t("label.agencyName") }} <span class="required">*</span></label
          >
          <input
            type="text"
            id="agencyName"
            v-model="agencyName"
            :required="type === 'preagency'"
          />
        </div>
        <div class="form-element">
          <label for="agencyEmail"
            >{{ $t("label.agencyEmail") }}
            <span class="required">*</span></label
          >
          <input
            type="email"
            id="agencyEmail"
            v-model="agencyEmail"
            :required="type === 'preagency'"
          />
        </div>
        <div class="form-element">
          <label for="emergencyNumber"
            >{{ $t("label.emergencyNumber") }}
            <span class="required">*</span></label
          >
          <input
            type="email"
            id="emergencyNumber"
            v-model="emergencyNumber"
            :required="type === 'preagency'"
          />
        </div>
      </div>

      <!-- Contract Information -->
      <div class="form-container">
        <p class="form-title">
          {{ $t("label.contractInformation") }}
        </p>
        <div class="form-element">
          <label for="society"
            >{{ $t("label.contractForm.society") }}
            <span class="required">*</span></label
          >
          <input
            type="text"
            id="society"
            v-model="contractInfo.society"
            :disabled="contractInfoSave"
          />
        </div>
        <div class="double-form-element">
          <div>
            <label for="societyModel"
              >{{ $t("label.contractForm.statut") }}
              <span class="required">*</span></label
            >
            <input
              type="text"
              id="societyModel"
              v-model="contractInfo.societyModel"
            />
          </div>
          <div>
            <label for="societyCapital"
              >{{ $t("label.contractForm.capital") }}
              <span class="required">*</span></label
            >
            <input
              type="number"
              id="societyCapital"
              min="0"
              v-model="contractInfo.societyCapital"
            />
          </div>
        </div>
        <div class="form-element">
          <label for="societyAddress"
            >{{ $t("label.contractForm.address") }}
            <span class="required">*</span></label
          >
          <input
            type="text"
            id="societyAddress"
            v-model="contractInfo.societyAddress"
          />
        </div>
        <div class="form-element">
          <label for="societyAddress2"
            >{{ $t("label.contractForm.address") }} (2)</label
          >
          <input
            type="text"
            id="societyAddress2"
            v-model="contractInfo.societyAddress2"
          />
        </div>
        <div class="double-form-element">
          <div>
            <label for="societyZIP"
              >{{ $t("label.contractForm.zip") }}
              <span class="required">*</span></label
            >
            <input
              type="text"
              id="societyZIP"
              v-model="contractInfo.societyZIP"
            />
          </div>
          <div>
            <label for="societyCity"
              >{{ $t("label.contractForm.city") }}
              <span class="required">*</span></label
            >
            <input
              type="text"
              id="societyCity"
              v-model="contractInfo.societyCity"
            />
          </div>
        </div>
        <div class="double-form-element">
          <div>
            <label for="societyRegisterPlace">{{
              $t("label.contractForm.registerPlace")
            }}</label>
            <input
              type="text"
              id="societyRegisterPlace"
              v-model="contractInfo.societyRegisterPlace"
            />
          </div>
          <div>
            <label for="societyRegisterId">{{
              $t("label.contractForm.registerId")
            }}</label>
            <input
              type="text"
              id="societyRegisterId"
              v-model="contractInfo.societyRegisterID"
            />
          </div>
        </div>
        <div class="double-form-element">
          <div>
            <label for="country-list"
              >{{ $t("label.contractForm.country") }}
              <span class="required">*</span></label
            >
            <country-list id="country-list" class="list"></country-list>
          </div>
          <div>
            <label for="license">{{ $t("label.contractForm.licence") }}</label>
            <input
              type="text"
              id="societyRegisterId"
              v-model="contractInfo.licence"
            />
          </div>
        </div>
        <div class="double-form-element">
          <div>
            <label for="tvaNumber">{{
              $t("label.contractForm.tvaNumber")
            }}</label>
            <input
              type="text"
              id="tvaNumber"
              v-model="contractInfo.tvaNumber"
            />
          </div>
          <div>
            <label for="societyRepresentative"
              >{{ $t("label.contractForm.representative") }}
              <span class="required">*</span></label
            >
            <input
              type="text"
              id="societyRepresentative"
              v-model="contractInfo.societyRepresentative"
            />
          </div>
        </div>
      </div>

      <div class="offers-container" hidden>
        <ul class="form-list">
          <li>
            <input type="checkbox" id="offer-checkbox" v-model="askForOffers" />
            <label for="offer-checkbox">{{ $t("label.askForOffers") }}</label>
          </li>
        </ul>
      </div>
      <button
        id="confirmation-button"
        @click="submitEdit"
        :disabled="checkForm"
        class="save-button"
        v-if="!$route.query.agency"
      >
        {{ $t("buttons.save") }}
      </button>
    </div>
    <div v-else>
      <h1>{{ $t("label.informationSaved") }}</h1>
      <br />
      <span>{{ $t("label.preAgencyExplanations1") }}</span>
      <br /><span>
        {{
          $t("label.preAgencyExplanations2") +
          " " +
          this.countdown +
          " " +
          $t("label.secondsDot")
        }}</span
      >
      <a href="https://www.travelassist.io">{{ $t("buttons.go") }}</a>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";
import CountryList from "./CountryList.vue";

export default {
  name: "PreAgencyForm",
  components: { CountryList },
  data() {
    return {
      type: "preagency",
      preAgencyID: this.$route.query.agency,
      name: "",
      surname: "",
      email: "",
      agencyName: "",
      agencyEmail: "",
      gender: "male",
      emergencyNumber: "",
      emailConfirmation: "",
      acceptContract: false,
      acceptTOU: false,
      successSave: false,
      countdown: 10,
      contractNotRead: true,
      contractInfoSave: false,
      askForOffers: true,
      contractInfo: {
        society: "",
        societyModel: "",
        societyCapital: "",
        societyAddress: "",
        societyAddress2: "",
        societyZIP: "",
        societyCity: "",
        societyRegisterPlace: "",
        societyRegisterID: "",
        licence: "",
        tvaNumber: "",
        societyRepresentative: ""
      }
    };
  },
  created() {
    if (this.$route.query.agency) {
      this.type = "agency";
    }
  },
  computed: {
    checkForm: function () {
      if (!this.name) {
        return true;
      }
      if (!this.surname) {
        return true;
      }
      if (!this.email) {
        return true;
      }
      if (!this.emergencyNumber) {
        return true;
      }
      if (!this.agencyName) {
        return true;
      }
      if (!this.agencyEmail) {
        return true;
      }
      if (!this.contractInfo.society) {
        return true;
      }
      if (!this.contractInfo.societyModel) {
        return true;
      }
      if (!this.contractInfo.societyCapital) {
        return true;
      }
      if (!this.contractInfo.societyAddress) {
        return true;
      }
      if (!this.contractInfo.societyZIP) {
        return true;
      }
      if (!this.contractInfo.societyCity) {
        return true;
      }
      if (!this.contractInfo.societyRepresentative) {
        return true;
      }

      return false;
    },
    emailCheck: function() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.email.toLowerCase())) {
        if (
          this.email != this.emailConfirmation &&
          this.emailConfirmation != ""
        ) {
          return this.$t("error.emailCheck");
        }
        return "";
      } else {
        if (this.email != "") {
          return this.$t("error.invalidEmail");
        } else {
          return "";
        }
      }
    }
  },
  methods: {
    submitEdit: async function () {
      try {
        this.$store.dispatch("load", this.$t("loadMessage.savingAgency"));

        let newData = {
          name: this.name,
          surname: this.surname,
          email: this.email,
          agencyEmail: this.agencyEmail,
          agencyName: this.agencyName,
          gender: this.gender,
          emergencyNumber: this.emergencyNumber,
          acceptContract: this.acceptContract,
          askForOffers: this.askForOffers,
          contractInfo: {
            society: this.contractInfo.society,
            societyType: this.contractInfo.societyModel,
            capital: this.contractInfo.societyCapital,
            address: this.contractInfo.societyAddress,
            zipCode: this.contractInfo.societyZIP,
            city: this.contractInfo.societyCity,
            commercialRegisterLocation: this.contractInfo.societyRegisterPlace,
            commercialRegisterNumber: this.contractInfo.societyRegisterID,
            country: this.$store.state.countrySelector,
            licence: this.contractInfo.licence,
            tvaNumber: this.contractInfo.tvaNumber,
            representative: this.contractInfo.societyRepresentative
          }
        };
        if (this.address2 !== "") {
          newData.contractInfo.address2 = this.contractInfo.societyAddress2;
        }
        let res = await HandleApi.postPreAgency(newData);
        if (res.status == 200) {
          this.$store.dispatch("unload");
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.saved", {
              object: this.$t("notification.agency"),
            })
          );
          this.successSave = true;
          window.setTimeout(() => (this.countdown = 9), 1000);
          window.setTimeout(() => (this.countdown = 8), 2000);
          window.setTimeout(() => (this.countdown = 7), 3000);
          window.setTimeout(() => (this.countdown = 6), 4000);
          window.setTimeout(() => (this.countdown = 5), 5000);
          window.setTimeout(() => (this.countdown = 4), 6000);
          window.setTimeout(() => (this.countdown = 3), 7000);
          window.setTimeout(() => (this.countdown = 2), 8000);
          window.setTimeout(() => (this.countdown = 1), 9000);
          window.setTimeout(() => (this.countdown = 0), 10000);
          window.setTimeout(
            () => (location.href = "https://www.travelassist.io"),
            10000
          );
        }
      } catch (err) {
        this.$store.dispatch("unload");
        this.$store.dispatch("push_error_message", err);
      }
    },
    checkHeight() {
      if (
        Math.round(
          this.$refs.readText.scrollHeight - this.$refs.readText.scrollTop
        ) === Math.round(this.$refs.readText.clientHeight)
      ) {
        this.contractNotRead = false;
      }
    },
  },
};
</script>

<style scoped>
.error-message {
  /*border: 1px solid red;*/
  color: white;
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.5);
  max-width: 90%;
  padding: 10px 10px;
  margin: 0 auto;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message svg {
  margin-right: 10px;
}

.save-button {
  display: block;
  background-color: rgb(9, 201, 111);
  color: #ffffff;
  border: none;
  border-radius: 10px;
  margin: 5px auto;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.errorField {
  -moz-box-shadow: 0 0 3px red;
  -webkit-box-shadow: 0 0 3px red;
  box-shadow: 0 0 3px red;
}

.to-read {
  width: 70%;
  max-width: 1000px;
  padding: 20px;
  background-color: lightgrey;
  border: 1px solid darkgrey;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  margin: 0 auto;
}

.read-text {
  padding: 10px;
  background-color: white;
  border: 1px solid darkgrey;
  border-radius: 0.25em;
  line-height: 15px;
  height: 200px;
  overflow-y: scroll;
}

.to-read-paragraph {
  text-align: left;
  margin: 5px 0;
}

.form-container {
  width: 30%;
  margin: 10px auto 30px auto;
  padding: 0 5%;
}
.offers-container {
  width: 30%;
  margin: 10px auto 30px auto;
  padding: 0 5%;
}
.offers-container label {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1000px) {
  .form-container {
    width: 80%;
  }
  .offers-container {
    width: 80%;
  }
}
.form-title {
  font-size: 110%;
  font-weight: bold;
}
.form-element {
  position: relative;
  margin: 20px 0;
}

.double-form-element {
  position: relative;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.double-form-element > div {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.double-form-element > div:nth-child(1) {
  align-self: flex-start;
  padding: 0 10px 0 0;
}
.double-form-element > div:nth-child(2) {
  align-self: flex-end;
  padding: 0 0 0 10px;
}

.form-element > input,
.double-form-element > div > input {
  width: 100%;
  display: block;
  margin: 5px 0;
  padding: 8px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.form-element > input:disabled,
.double-form-element > div > input:disabled {
  background-color: #e5e5e5;
}
.form-element > input:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}
.form-element > span {
  display: block;
}
.form-element > label,
.label,
.double-form-element > div > label {
  display: block;
  font-weight: bold;
  color: #666666;
}
.form-list {
  display: inline-block;
  padding: 0;
  margin: 5px 0;
}
.form-list::after {
  clear: both;
  content: "";
  display: table;
}
.form-list > li {
  display: inline-block;
  position: relative;
  user-select: none;
  margin: 0 26px 16px 0;
  float: left;
}
/* Custom Radio Button */
.form-list input[type="radio"],
.form-list input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 2;
}
.form-list input[type="radio"] + label,
.form-list input[type="checkbox"] + label {
  padding-left: 24px;
}
.form-list input[type="radio"] + label::before,
.form-list input[type="radio"] + label::after,
.form-list input[type="checkbox"] + label::before,
.form-list input[type="checkbox"] + label::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.form-list input[type="radio"] + label::before,
.form-list input[type="checkbox"] + label::before {
  border: 1px solid #cfd9db;
  background: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.form-list input[type="radio"] + label::before,
.form-list input[type="radio"] + label::after {
  border-radius: 50%;
}
.form-list input[type="checkbox"] + label::before,
.form-list input[type="checkbox"] + label::after {
  border-radius: 0.25em;
}
.form-list input[type="radio"] + label::after,
.form-list input[type="checkbox"] + label::after {
  background-color: #2c3e50;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.4);
  display: none;
}
.form-list input[type="radio"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%223%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.form-list input[type="checkbox"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%20stroke-miterlimit%3D%2210%22%20points%3D%225%2C8%207%2C10%2011%2C6%20%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.form-list input[type="radio"]:focus + label::before,
.form-list input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.6);
}
.form-list input[type="radio"]:checked + label::after,
.form-list input[type="checkbox"]:checked + label::after {
  display: block;
}
.form-list input[type="radio"]:checked + label::before,
.form-list input[type="radio"]:checked + label::after,
.form-list input[type="checkbox"]:checked + label::before,
.form-list input[type="checkbox"]:checked + label::after {
  animation: cd-bounce 0.3s;
}

@-webkit-keyframes cd-bounce {
  0%,
  100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@-moz-keyframes cd-bounce {
  0%,
  100% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(0.8);
  }
}
@keyframes cd-bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

.list {
  width: 100%;
  display: block;
  margin: 5px 0;
  padding: 8px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}

.required {
  color: #034c57;
}
</style>
